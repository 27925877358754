<template>
	<CoverageTemplate class="mb-5" :page-title="$t('title')">
		<BaseCard
			v-for="benefit in optionalBenefits"
			:key="benefit.code"
			class="base-card"
			automation-id="base-card"
		>
			<BRow>
				<BCol cols="11">
					<p class="h3">{{ benefit.label }}</p>
				</BCol>
			</BRow>
			<BRow>
				<BCol cols="12">
					<p>{{ benefit.description }}</p>
				</BCol>
			</BRow>
			<BRow>
				<BCol cols="11">
					<p class="h4">{{ $t('coverageAmount') }}</p>
				</BCol>
			</BRow>

			<template v-for="(benefitDetails, index) in benefit.benefitDetails">
				<BRow :key="benefitDetails.lob" no-gutters>
					<BCol
						:class="['pr-0', locale !== 'en' ? 'col-md-3' : 'col-md-2']"
						cols="6"
						xl="2"
						lg="3"
						sm="4"
					>
						<!-- eslint-disable-next-line -->
						<p class="mb-0">{{ benefitDetails.lobLabel }}:</p>
					</BCol>
					<BCol
						:class="[
							locale !== 'en' ? 'currency-col-fr' : '',
							'text-right',
							'pr-md-4',
							'pr-lg-2',
							'pr-xl-4'
						]"
						cols="5"
						lg="2"
						md="3"
						sm="4"
					>
						<DisplayCurrency
							:id="`${benefit.label}-display-currency-${index}`"
							class="currency"
							:currency="benefitDetails.amount"
						/>
					</BCol>
				</BRow>
			</template>

			<div v-if="benefit.beneficiaryDetails.length > 0" class="pt-4">
				<template v-for="beneficiaryDetails in benefit.beneficiaryDetails">
					<!-- This is the header of the beneficiaries table -->
					<BRow :key="beneficiaryDetails.beneficiaryCode" class="name-header pt-2 pl-2 m-0">
						<BCol sm="12" md="8" lg="8" class="header"
							><p class="mb-0">{{ $t('beneficiaryInfo') }}</p>
						</BCol>
					</BRow>
					<!-- Loop Through all the beneficiaries and display their names and percentage amounts and a modal with information about what a beneficiary is -->
					<template v-for="(beneficiary, index) in beneficiaryDetails.beneficiaryDetails">
						<BRow :key="beneficiary.name" no-gutters class="beneficiary-list">
							<BCol class="table-cells" cols="12" lg="5" md="5" sm="4">
								<TextModal
									:id="getUniqueId(`${benefit.label}-text-modal-${index}`)"
									class="text-modal"
									:id-increment="index"
									:text="beneficiaryDetails.beneficiaryLabel"
									:modal-title="$t('modal.beneficiaryHeader')"
									:automation-id="getAutomationId('textModal')"
								>
									<div>
										<p>{{ $t('modal.beneficiaryTextOne') }}</p>
										<p>{{ $t('modal.beneficiaryTextTwo') }}</p>
										<p>{{ $t('modal.beneficiaryTextThree') }}</p>
									</div>
								</TextModal>
							</BCol>
							<BCol class="table-cells" cols="12" lg="4" md="4" sm="4">
								<p class="mb-0">{{ beneficiary.name }}</p>
							</BCol>
							<BCol class="text-position" cols="12" lg="3" md="3" sm="4">
								<!-- eslint-disable-next-line -->
								<p class="mb-0">{{ beneficiary.percentage }}%</p>
							</BCol>
						</BRow>
					</template>
					<!-- Loop through all the trustee's and display their names and a modal with information about what a trustee is.  -->
					<template v-for="(trustee, index) in beneficiaryDetails.trusteeDetails">
						<BRow :key="trustee.name" no-gutters class="beneficiary-list">
							<BCol class="table-cells" cols="12" lg="5" md="5" sm="4">
								<TextModal
									:id="getUniqueId(`${benefit.label}-text-trustee-modal-${index}`)"
									class="text-modal"
									:id-increment="`-trustee-${index}`"
									:text="beneficiaryDetails.trusteeLabel"
									:modal-title="$t('modal.trusteeHeader')"
									:automation-id="getAutomationId('textModal')"
								>
									<div>
										<p>{{ $t('modal.trusteeTextOne') }}</p>
										<p>{{ $t('modal.trusteeTextTwo') }}</p>
									</div>
								</TextModal>
							</BCol>
							<BCol class="table-cells" cols="12" lg="4" md="4" sm="4">
								<p class="mb-0">{{ trustee.trusteeName }}</p>
							</BCol>
							<BCol class="text-position" cols="12" lg="3" md="3" sm="4">
								<p></p>
							</BCol>
						</BRow>
					</template>
				</template>
			</div>
		</BaseCard>
	</CoverageTemplate>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Benefits from '@/models/coverage/BenefitsScepter';
import BaseCard from '@/components/common/card/BaseCard';
import TextModal from '@/components/common/TextModal.vue';
import CoverageTemplate from './CoverageTemplate.vue';
import DisplayCurrency from '@/components/common/DisplayCurrency';
import { DASHBOARD, COVERAGE } from '@/constants/Routes';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'OptionalBenefitsPage',
	components: {
		CoverageTemplate,
		DisplayCurrency,
		BaseCard,
		BRow,
		TextModal,
		BCol
	},
	mixins: [IdMixin, BreadcrumbsManager],
	watch: {
		locale() {
			this.getOptionalBenefits();
		}
	}
})
export default class OptionalBenefitsPage extends Vue {
	optionalBenefits = {};

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	async beforeMount() {
		this.getOptionalBenefits();
	}

	async getOptionalBenefits() {
		this.optionalBenefits = await Benefits.getOptionalBenefits(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);
	}

	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.optionalBenefits', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr'), to: { name: COVERAGE.MY_COVERAGE } },
				{ text: this.$t('breadcrumb.optionalBenefits', 'fr') }
			]
		);
	}
}
</script>

<style lang="scss" scoped>
.name-header {
	background-color: $primary;
	border-radius: 10px 10px 0px 0px;
	min-height: 40px;
	color: $white;
	max-width: 80%;
	@media (max-width: 991px) {
		max-width: 100%;
	}
	@media (max-width: 576px) {
		padding: 0;
	}
}
.header {
	font-size: 18px;
	font-family: $josefin-font-family;
	@media (max-width: 576px) {
		text-align: center;
		padding: 0;
	}
}
.beneficiary-list {
	border: 1px solid $gray-light;
	padding: 10px 20px;
	font-size: 15px;
	max-width: 80%;
	@media (max-width: 991px) {
		max-width: 100%;
	}
	@media (max-width: 576px) {
		padding: 10px 23px;
	}
}
.table-cells {
	@media (max-width: 576px) {
		text-align: center;
	}
}
.text-position {
	text-align: right;
	padding-right: 30px;
	@media (max-width: 576px) {
		text-align: center;
		padding-right: 0px;
	}
}
.currency {
	font-family: $font-family-regular;
	font-size: 18px;
	font-weight: 400;
}
.currency-col-fr {
	padding-left: 5px;
}
.text-modal ::v-deep .link {
	font-weight: 400;
}
.text-modal ::v-deep .modal-dialog {
	max-width: 540px;
}
.text-modal ::v-deep .modal-body {
	font-size: 17px;
	text-align: left;
}
.text-modal ::v-deep .modal-header {
	font-size: 21px;
	text-align: left;
}
</style>

<i18n>
{
	"en": {
		"title": "Optional Benefits",
		"coverageAmount": "Coverage Amounts",
		"beneficiaryInfo": "Beneficiary Information",
		"breadcrumb": {
      		"home": "Home",
      		"myCoverage": "Plan Coverage",
      		"optionalBenefits": "Optional Benefits"
    	},
		"modal": {
			"beneficiaryHeader": "Assigned/Designated Beneficiary",
			"beneficiaryTextOne": "Your assigned/designated beneficiary is the person (or persons) who will receive any benefits eligible to be paid upon your death.",
			"beneficiaryTextTwo": "If you don't assign a beneficiary, any benefits paid upon your death will be paid to your estate.",
			"beneficiaryTextThree": "If you've chosen coverage for your spouse or a dependent, you are designated as the beneficiary for their coverage by default.",
			"trusteeHeader": "Trustee",
			"trusteeTextOne": "If a benefit is payable upon your death and your beneficiary is under the age of majority the trustee will retain legal control of the benefit received until the beneficiary/contingent reaches the age of majority",
			"trusteeTextTwo": "Your trustee must have reached the age of majority. Your primary beneficiary can't be named as trustee for a contingent."
		}
	},
	"fr": {
		"title": "Garanties facultatives",
		"coverageAmount": "Montants de couverture",
		"beneficiaryInfo": "Renseignements sur les bénéficiaires",
		"breadcrumb": {
      		"home": "Accueil",
      		"myCoverage": "Couverture du régime",
     		"optionalBenefits": "Garanties facultatives"
    	},
		"modal": {
			"beneficiaryHeader": "Bénéficiaire assigné/désigné",
			"beneficiaryTextOne": "Votre bénéficiaire assigné/désigné est la personne (ou les personnes) qui recevra toute prestation admissible payable au moment de votre décès. ",
			"beneficiaryTextTwo": "Si vous ne désignez pas de bénéficiaire, toute prestation payable à votre décès sera versée à votre succession.",
			"beneficiaryTextThree": "Si vous avez choisi une couverture pour votre conjoint(e) ou une personne à charge, vous êtes la personne bénéficiaire de leur couverture par défaut. ",
			"trusteeHeader": "Fiduciaire",
			"trusteeTextOne": "Si une prestation est payable au moment de votre décès et que votre bénéficiaire n'a pas atteint l'âge de la majorité, le fiduciaire conservera le contrôle légal de la prestation reçue jusqu'à ce que le bénéficiaire ou le bénéficiaire subsidiaire atteigne l'âge de la majorité.",
			"trusteeTextTwo": "Votre fiduciaire doit avoir atteint l'âge de majorité. Votre bénéficiaire principal ne peut être désigné comme fiduciaire pour un bénéficiaire subsidiaire."
		}
	}
}
</i18n>
